import React from 'react';
import { Link } from 'react-router-dom';

import './Navbar.css';

const Navbar = () => {
  const [isNavbarOpen, setIsNavbarOpen] = React.useState(false);

  return (
    <>
      <aside className={`navbar ${!isNavbarOpen ? '' : 'active'}`}>
        <section className="c-section">
          <nav>
            <Link to="/">
              <h1 className="b-title">Home</h1>
            </Link>
            <a href="https://jekfolio.jeknowledge.com/">
              <h1 className="b-title">Our Work</h1>
            </a>
            <Link to="/recruit">
              <h1 className="b-title">Recruit</h1>
            </Link>
            <a href="https://medium.com/jeknowledge">
              <h1 className="b-title">Medium</h1>
            </a>
            <Link to="/team">
              <h1 className="b-title">Team</h1>
            </Link>
          </nav>
          <div className="navbar-footer text s-text">
            <img src="Images/logo-text-white.svg" alt="White Logo jeK" />
            <Link to="/hello">Get in touch!</Link>
            <span>geral@jeknowledge.com</span>
            <span>+351 913 242 691</span>
            <span> This website is</span>
            <span> © jeKnowledge 2021.</span>
          </div>
        </section>
      </aside>
      <header
        className="b-title"
        style={{ mixBlendMode: isNavbarOpen ? 'initial' : 'multiply' }}
      >
        <Link to="/">
          <span className='lightblue'>jeKnowledge</span>
        </Link>
        <div
          className="menu-button"
          role="button"
          onClick={() => setIsNavbarOpen(!isNavbarOpen)}
        >
          <span>Menu</span>
          <div className="ball"></div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
