import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import Hello from './pages/Hello';
import Recruit from './pages/Recruit';
import Team from './pages/Team';
import Events from './pages/Events';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/hello" element={<Hello />} />
        <Route path="/recruit" element={<Recruit />} />
        <Route path="/team" element={<Team />} />
        <Route path="/events/:id" element={<Events />} />
      </Routes>
    </Router>
  );
}

export default App;
