import React, { useState, useEffect } from 'react';
import {GoPersonFill} from "react-icons/go";

import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import useFetch from '../../useFetch';

import './Team.css';

const deps = [
  { title: 'All', path: 'all' },
  { title: 'Direction', path: 'dir' },
  { title: 'Innovation', path: 'inov' },
  { title: 'Technology', path: 'tech' },
  { title: 'Intern', path: 'int' },
];

const Team = () => {
  const { data, error, isPending } = useFetch('https://jenoledje-website-server.vercel.app/getAll');
  const [dep, setDep] = useState('all');
  const [members, setMembers] = useState(data);

  useEffect(() => {
    setMembers(
      dep === 'all'
        ? data
        : data?.filter(
            (member) =>
              member.dep === dep.charAt(0).toUpperCase() + dep.slice(1)
          )
    );
  }, [data, dep]);

  if (isPending || error) return null;

  return (
    <>
      <Navbar />
      <section className="c-section top-section">
        <h1 className="b-title title">Team</h1>
        <h2 className="b-title subtitle is-2">
          "More than a family, a company."
        </h2>
        <div className="content">
          <p className="b-text is-large">
            jeKnowledge is filled with proactive, imaginative and cool people!
            We do Web-Development, Graphic Design, Development, Events
            Organization, Filmmaking and many more. We welcome any challenge! 💙
            <br />
            Scroll down for a peak into our family.
          </p>
        </div>
      </section>
      <section className={`departments ${dep}`}>
        <div className="departments-nav">
          {deps.map(({ title, path }) => (
            <div
              key={path}
              className={`dep ${path} b-title`}
              role="button"
              onClick={() => setDep(path)}
            >
              {title}
            </div>
          ))}
        </div>
        <section className="c-jekers">
          {members?.map(
            ({ name, lastname, desc, image, linkedin, behance, github, cargo }) => (
              <div
                key={name + lastname}
                className="jeker"
              >
                {image!="false" ? <img src={image} alt={name} /> : <GoPersonFill className='placeholder'/>}
                <div className="s-title title is-5 jeker-content-container">
                  <span className='jeker-names'>
                    {name} {lastname}
                  </span>
                  <div className="jeker-line">
                    <span className="s-subtitle jeker-role">{cargo}</span>
                    <span className="s-subtitle subtitle jeker-dep">{desc}</span>
                  </div>
                  <div className="jeker-contacts">
                    {linkedin !== 'false' && (
                      <a href={linkedin}>
                        <img src="Images/linkedin_team.svg" alt="LinkedIn" />
                      </a>
                    )}
                    {behance !== 'false' && (
                      <a href={behance}>
                        <img src="Images/behance_team.svg" alt="Behance" />
                      </a>
                    )}
                    {github !== 'false' && (
                      <a href={github}>
                        <img src="Images/github_team.svg" alt="GitHub" />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            )
          )}
        </section>
      </section>
      <Footer />
    </>
  );
};

export default Team;
