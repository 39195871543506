import "./Events.css"
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import useFetch from '../../useFetch';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

const Events = () => {
    const { data, error, isPending } = useFetch('https://jenoledje-website-server.vercel.app/getProjects');
    const [event, setEvent] = useState({});

    //get the event from the url
    const { id } = useParams();
    // filter the data to get the event with the id
    // const event = data.filter((event) => event.id === id);
    // console.log(event)

    //if (isPending || error) return null;

    useEffect(() => {
        if(data === null) return;
        for(let i = 0; i < data.length; i++){
            let name = data[i].name.split(/[ .]/).join("").toLowerCase();
            console.log(id)
            console.log(name)
            if(id === name){
                setEvent(data[i])
            }
        }
    }, [data, id]);
    
    return (
        <>
            <Navbar />
                <section className="c-section top-section event-section">
                    {/*<div className="event-writting-info">
                        <h4 className="b-title">{event.writer}</h4>
                        <h5>{event.position}</h5>
                    </div>*/}
                    <h1 className="title b-title event-title">{event.name}</h1>
                    <div className="event-info">
                        <p className="event-description">{event.description}</p>
                        <h6>{event.date}</h6>
                    </div>
                    <div className="event-grid-images">
                        {event.photos?.map((photo) => (
                            <img src={photo} alt="" className="event-image"/>
                        ))}
                    </div>
                    {event.link!==null && <h2 className="event-redirect"> Want to know more about it? Visit our website: <a href={event.link} target="blank"> here </a></h2>}
                </section>
            <Footer />
        </>
    )
}

export default Events