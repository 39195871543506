import React, { useRef, useState } from 'react';
import Recaptcha from 'react-google-recaptcha';
import fetch from 'node-fetch';
import emailjs from 'emailjs-com';

import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

import './Hello.css';

const Hello = () => {
  const captchaRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captchaRef.current.getValue()) {
      alert('Please verify that you are not a robot.');
      
    }
    else {

      
      const emailData = { 
        user_name: e.target.name.value,
        user_email: e.target.email.value,
        message: e.target.message.value,
      };
      console.log(emailData);
      emailjs.send(
          'service_znmajp1',
          'template_sqc2tj9',
          emailData,
          'ox1c9H3JQJWQdT3fX'
        ) .then (
          (result) => {
            console.log('Email sent successfully', result);
          },
          (error) => {
            console.log('Email could not be sent:', error);
          }
        );
    }
            
  };

  //scroll to top of the page on load
  window.scrollTo(0, 0);

  return (
    <>
      <Navbar />
      <div className="h-section">
        <div className="title b-title">
          <h1>Hello!</h1>
          <h2>We'd love to hear from you.</h2>
        </div>
        <div className="hello-contacts">
          <h3>You can find us here...</h3>
          <div className="contact">
            <img src="../Images/email_hello.svg" alt="" />
            <p>geral@jeknowledge.com</p>
          </div>
          <div className="contact">
            <img src="../Images/phone_hello.svg" alt="" />
            <p>+351 913 242 691</p>
          </div>
          <div className="contact">
            <img src="../Images/address_hello.svg" alt="" />
            <p>
              Departamento de Fisica, Rua Larga sala B3 <br />
              3004-516 Coimbra
            </p>
          </div>
        </div>
        <img
          src="https://i.imgur.com/EKGX7td.jpg"
          alt=""
          className="hello-team-photo"
        />
        <form className="f-section" onSubmit={handleSubmit}>
          <h3>Send us a message!</h3>
          <label for="name">What's your name?</label>
          <input title="name" type="name" id="name" required />
          <label for="email">What's your email?</label>
          <input title="email" type="email" id="email" required />
          <label for="messsage">Write your message here!</label>
          <textarea name="messsage" id="message" rows={6} required />
          <Recaptcha
            className="captcha"
            sitekey="6LcQIXspAAAAAKa63n2cj_9g_1RxDmgZm7Luhhot"
            ref={captchaRef}
          ></Recaptcha>
          <button className="button is-info" type="submit" title="button">
            Let's go!
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Hello;
