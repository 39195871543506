export const projectsCarouselSettings = {
  dots: false,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  speed: 5000,
  autoplaySpeed: 0,
  cssEase: 'linear',
  draggable: false,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
      },
    },

    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const alumnisCarouselSettings = {
  dots: false,
  infinite: true,
  slidesToShow: 2.5,
  slidesToScroll: 1,
  autoplay: true,
  speed: 12000,
  autoplaySpeed: 0,
  cssEase: 'linear',
  draggable: false,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },

    {
      breakpoint: 1020,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

export const projects = [
  {
    name: 'Dotworks',
    description: ' My name is dot. Dotworks',
    image: 'Images/dot.png',
    link: '/events/dotworks/',
  },
  {
    name: 'jeK Academy',
    description: 'A levar engenharia aos mais novos',
    image: 'Images/Academy.png',
    link: '/events/jeknowledgeacademy/',
  },
  {
    name: 'Shift APPens',
    description: '1 IDEA. 4 FRIENDS. 48 HOURS.',
    image: 'Images/Shift.png',
    link: '/events/shiftappens/',
  },
  {
    name: 'Coimbra.Blockchain',
    description: 'Blockchain knowledge for everyone',
    image: 'Images/CB.png',
    link: '/events/coimbrablockchain/',
  },
];
