import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Footer from '../../components/Footer';
import useFetch from '../../useFetch';

import NavbarHomePage from './NavbarHomePage';
import Newsletter from './Newsletter';
import {
  alumnisCarouselSettings,
  projects,
  projectsCarouselSettings,
} from './utils';
import './Navbar.css';
import './Style.css';

const Home = () => {
  const {
    data: partners,
    isPending: isLoadingPartners,
    error: errorPartners,
  } = useFetch('https://jenoledje-website-server.vercel.app/partner');
  const {
    data: alumnis,
    isPending: isLoadingAlumnis,
    error: errorAlumnis,
  } = useFetch('https://jenoledje-website-server.vercel.app/alumni');

  if (isLoadingPartners || errorPartners) return null;
  if (isLoadingAlumnis || errorAlumnis) return null;

  return (
    <>
      <NavbarHomePage />
      <section className="c-contact">
        <p className="b-title title is-2">We don't bite!</p>
        <p className="subtitle s-text is-white is-4">
          <span>Get in touch </span>
          <Link to="/hello">here.</Link>
        </p>
      </section>
      <section className="c-portfolio">
        <Slider {...projectsCarouselSettings}>
          {projects.map(({ name, description, image, link }) => (
            <div className="c-project-slide-block" key={name}>
              <div className="c-project-text">
                <h3 className="s-title title is-3 hightlight">{name}</h3>
                <br />
                <h4 className="s-text subtitle is-3 hightlight">
                  {description}
                </h4>
              </div>
              <a href={link}>
                <img src={image} alt={name} />
              </a>
            </div>
          ))}
        </Slider>
      </section>
      <section className="c-section">
        <div>
          <h1 className="b-title title">jeK Who?</h1>
          <div className="content">
            <p className="b-text is-large">
              jeKnowledge - FCTUC's Junior Enterprise - was founded in 2008 with
              the purpose of providing FCTUC's students the opportunity of
              applying their Academic knowledge on internal and external
              projects, learning new skills and practicing with real life
              situations.
            </p>
          </div>
        </div>
        <div className="content-center">
          <div className="center">
            <div className="video-highlight">
              <h3 className="s-title title is-3 hightlight">We make videos!</h3>
              <br />
              <h4 className="s-text subtitle is-3 hightlight">
                And they're super cool...
              </h4>
            </div>
            <div className="c-video">
              <iframe
                src="https://player.vimeo.com/video/273569355?h=dac0ac02d9"
                width="640"
                height="360"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="video example"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="c-jeker c-section invert">
        <div>
          <h1 className="b-title title">Dream Team </h1>
          <h2 className="b-title subtitle is-2">
            "More than a family, a company."
          </h2>
          <div className="content">
            <p className="b-text is-large">
              jeK is home to some of the greatest minds we’ve ever seen. We love
              to work together, have fun together, even live together sometimes!
            </p>
          </div>
        </div>
        <div className="image-content team-image invert">
          <Link
            to="/team"
            className="image-title s-title title is-3 hightlight invert"
          >
            The Family
          </Link>
        </div>
      </section>
      <section className="c-jeker c-section">
        <div>
          <h1 className="b-title title">Dream Job</h1>
          <h2 className="b-title subtitle is-2">The one we love doing!</h2>
          <div className="content">
            <p className="b-text is-large">
              We make sure your website, app or event is on point & on time,
              every single time, making sure everyone enjoys the process!
            </p>
          </div>
        </div>
        <div className="image-content work-image">
          <a
            href="https://shiftappens.com/"
            target="_blank"
            rel="noreferrer"
            className="image-title s-title title is-3 hightlight"
          >
            Shift APPens
          </a>
        </div>
      </section>
      <Newsletter />
      <section className="c-section top-section">
        <h1 className="b-title title">jeK World </h1>
        <h2 className="b-title subtitle is-2">
          We've worked with so many cool people!
        </h2>
        <div className="content">
          <p className="b-text is-large">
            Here are some of our most prized partners and clients.
          </p>
        </div>
      </section>
      <section className="c-partner">
        {partners?.map((data) => {
          return (
            <div
              key={data.link}
              className="partner"
              style={{ backgroundImage: `url(${data.link})` }}
            ></div>
          );
        })}
      </section>
      <section className="c-testimonial">
        <h2 className="b-title subtitle is-2">Alumni ❤️</h2>
        <Slider {...alumnisCarouselSettings}>
          {alumnis?.map((alumni) => (
            <div>
              <article>
                <blockquote className="b-text sized-text">
                  {alumni.desc}
                </blockquote>
                <h3 className="s-title title is-3 hightlight">
                  {alumni.author}
                </h3>
                <br />
                <h4 className="s-text subtitle is-3 hightlight">
                  {alumni.dep} {alumni.date}
                </h4>
              </article>
            </div>
          ))}
        </Slider>
      </section>
      <Footer />
    </>
  );
};

export default Home;
