import React, { useReducer } from 'react';
import axios from 'axios';

const initialState = {
  email: '',
};

const reducer = (user, action) => {
  switch (action.type) {
    case 'change':
      const { name, value } = action;
      return { ...user, [name]: value };
    case 'error':
      console.log('Erro');
      break;
    default:
      return { ...user };
  }
};
const Newsletter = () => {
  const [user, dispatch] = useReducer(reducer, initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'change', name, value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ user });
    axios
      .post('jenoledje-website-server.vercel.app/email', { user })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        dispatch({ type: 'error' });
        console.log(err);
      });
  };

  return (
    <section className="c-contact">
      <p className="b-title title is-2">Like what you see? </p>
      <p className="subtitle s-text is-white is-4">
        Subscribe to our newsletter.
      </p>
      <form
        onSubmit={handleSubmit}
        className="field has-addons"
        style={{ justifyContent: 'center' }}
      >
        <input
          className="required email input"
          name="email"
          type="text"
          placeholder="Your Email"
          onChange={handleInputChange}
          value={user.email}
        />
        <button type="submit" className="button button is-info">
          Subscribe
        </button>
      </form>
    </section>
  );
};

export default Newsletter;
