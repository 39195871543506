import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './Style.css';

const phrases = [
  'jeK with an uppercase K.',
  'jenólas.',
  'jecnoladge.',
  'jenóledje.',
  'blue.',
  'informal.',
  'professional.',
  'more than a family...',
  'an enterprise.',
  'ambitious.',
  'proactive.',
];

const NavbarHomePage = () => {
  const updateanimation = useRef();
  const [index, setIndex] = useState(0);
  const [phrase, setPhrase] = useState(phrases[index]);
  const [navbarVisible, setNavbarVisible] = useState(false);
  const [Header, setHeader] = useState(false);



  useEffect(() => {
    const timer = setInterval(() => {
      setIndex(index + 1);
      setPhrase(phrases[(index + 1) % phrases.length]);
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [index, phrase]);

  useEffect(() => {
    updateanimation.current.animate({ opacity: [0, 1, 1, 1, 1, 0] }, 5000);
  }, [phrase]);


  const ToggleNav = () => {
    setNavbarVisible(!navbarVisible);
  };

  const ToggleHeader = () => {
    handleClick();
    setHeader(!Header);
  };

  window.addEventListener('scroll', () => {
    setHeader(false);
  });
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToTop = () => {
    if(document.querySelector(".c-contact") === null) return;
    window.scrollTo({
      top: document.querySelector(".c-contact").offsetTop,
      behavior: 'smooth',
    });
  }

  return (
    <div>
      <aside className={`navbar ${!navbarVisible ? '' : 'active'}`}>
        <section className="c-section">
          <nav>
            <Link to="/">
              <h1 className="b-title">Home</h1>
            </Link>
            <a href="https://jekfolio.jeknowledge.com/">
              <h1 className="b-title">Our Work</h1>
            </a>
            <Link to="/recruit">
              <h1 className="b-title">Recruit</h1>
            </Link>
            <a href="https://medium.com/jeknowledge">
              <h1 className="b-title">Medium</h1>
            </a>
            <Link to="/team">
              <h1 className="b-title">Team</h1>
            </Link>
          </nav>
          <div className="navbar-footer text s-text">
            <img src="Images/logo-text-white.svg" alt="White Logo jeK" />
            <Link to="/hello">Get in touch!</Link>
            <span>geral@jeknowledge.com</span>
            <span>+351 913 242 691</span>
            <span> This website is</span>
            <span> © jeKnowledge 2021.</span>
          </div>
        </section>
      </aside>
      <header
        className="b-title"
        style={{ mixBlendMode: navbarVisible ? 'initial' : 'multiply' }}
      >
        <Link to="/">
          <span className='lightblue'>jeKnowledge</span>
        </Link>
        <div
          className="menu-button"
          role="button"
          onClick={() => setNavbarVisible(!navbarVisible)}
        >
          <span>Menu</span>
          <div className="ball"></div>
        </div>
      </header>
      <section className="c-hero">
        <div className="c-hero-ov-1"></div>
        <div className="c-hero-ov-2"></div>
        

        <div className="c-hero-ov-text">
          <h1 className="b-title title">
            We are
            <br />
            <p ref={updateanimation} style={{ opacity: 0, color: '#1690C3' }}>
              {phrase}
            </p>
          </h1>
        </div>
      </section>
      <div className='scroll-down' onClick={scrollToTop}> 
        <div className='scroll-down-arrow'></div>
        <p className='scroll-down-text'> Hello there! </p>  
      </div>
    </div>
  );
};

export default NavbarHomePage;
